const ENV = process.env.NODE_ENV;

interface EnvVars {
  mode: 'development' | 'production' | 'test' | 'staging';
  auth: {
    url: string;
  };
  backend: {
    url: string;
  };
}

const CONFIG: EnvVars = {
  mode: 'production',
  auth: {
    url: 'https://auth.pandoper.onubo.com',
  },
  backend: {
    url: 'https://backend.pandoper.onubo.com/graphql',
  },
};

export { CONFIG };

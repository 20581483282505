import { useCallback, useState } from 'react'
import {useDropzone} from 'react-dropzone'
import { Loading } from '.'

interface ImagePickerProps {
    imageURL?: string,
    image?: File,
    onImageChanged: (image: File) => void,
}

export const ImagePicker = (props: ImagePickerProps) => {
    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)
    const onDrop = useCallback((acceptedFiles) => {
        // load file
        console.log(acceptedFiles)
        props.onImageChanged(acceptedFiles[0])
    }, [])

      const {getRootProps, getInputProps, isDragActive} = 
        useDropzone({
            onDrop, 
            accept: ['image/*'],
            multiple: false
        })

    return (
        <div {...getRootProps()} className="flex flex-col rounded-md border-2 border-dashed min-h-fit ">
            <input {...getInputProps()} />
            {
                props.image ? 
                    <img
                        className='rounded-md'
                        src={URL.createObjectURL(props.image)}
                        alt="Thumb"
                    />:
                props.imageURL ? 
                    <img
                    className='rounded-md'
                    src={props.imageURL}
                    onLoad={() => {
                        setIsImageLoaded(true)
                    }}
                    onError={(error) => console.log('error on loading', error)}
                    alt="Thumb"
                    /> : null
            }
            {
                (props.imageURL && !isImageLoaded)? 
                    <div >
                        <Loading />
                    </div>:
                    null
            }
            {
                isDragActive ?
                <div className='h-16'>Lascia qui il file...</div> :
                <div className='h-16'>Trascina qui l'immagine, o clicca per selezionare il file</div>
            }
        </div>
    )
}
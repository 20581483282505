import { CONFIG } from "../config";
import { appStorage } from "../storage";
import { userLogged } from "../storage/userState";

const defaultError = "Undefined Error";

const AUTH_URL = CONFIG.auth.url

export type TAuthResponse = {
  success: boolean,
  errors: string[],
  accessToken: string,
  refreshToken: string,
  info: {
    user: {
      _id: string
    },
    auth: {
      isActive: boolean,
      isEmailVerified: boolean
    }
  }
}

export const refreshToken = async (refreshToken: string) : Promise<TAuthResponse | undefined> => {
  try {
    const body = JSON.stringify({
      refreshToken,
    });

    const response = await fetch(AUTH_URL + '/auth/refreshToken', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const refreshResponse : TAuthResponse = await response.json();

    if (!refreshResponse || !refreshResponse.refreshToken || !refreshResponse.accessToken) {
      return undefined;
    }

    return refreshResponse;
  } catch ({ message }) {
    console.log(`Fetch New Access Token ERROR: ${message}`);
    return undefined;
  }
};

export const logout = async () => {
  await appStorage.removeObject('user')
  userLogged(null)
  return true
}

export const loginEmail = async (email: string, code: string) => {
  if (!AUTH_URL) {
    console.error("AUTH_URL must be set to login");
    throw new Error("AUTH_URL must be set to login");
  }

  if (!email || !code) {
    console.log("Email or code missing");
    return { success: false, error: 'email or code missing' };
  }

  try {
    const body = JSON.stringify({
      email,
      code,
    });

    const response = await fetch(AUTH_URL + "/auth/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    });

    const loginResponse = await handleLoginResponse(response);

    return loginResponse;
  } catch ({ message }) {
    console.error(`loginEmail ERROR: ${message}`);
    return {
      success: false,
      error: message,
    };
  }
};

export const requestCode = async (email: string) => {
  if (!AUTH_URL) {
    console.error("AUTH_URL must be set to login");
    throw new Error("AUTH_URL must be set to login");
  }

  if (!email) {
    console.log("Email missing");
    return { success: false, error: 'email missing' };
  }

  try {
    const body = JSON.stringify({
      email,
      application: "dashbaord"
    });

    const response = await fetch(AUTH_URL + "/auth/sendLoginCode", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    });

    const codeSentResponse : TAuthResponse = await response.json();  

    return codeSentResponse
  } catch ({ message }) {
    console.error(`requestCode ERROR: ${message}`);
    return {
      success: false,
      error: message,
    };
  }
};

const handleLoginResponse = async (response: Response) => {
  const loginResponse : TAuthResponse = await response.json();  
  if (
    !loginResponse ||
    !loginResponse.accessToken ||
    !loginResponse.refreshToken
  ) {
    console.log("Tokens missing");
    return {
      success: false,
      error: loginResponse?.errors[0] || defaultError,
    };
  }

  const auth = {
    accessToken: loginResponse.accessToken,
    refreshToken: loginResponse.refreshToken,
    user: {
      _id: loginResponse.info.user._id
    }
  };


  await appStorage.setObject('user',{
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
    user: auth.user
  });

  userLogged(auth.user._id)

  return {
    success: true,
    msg: "Login success",
  };
};

import React, { ChangeEvent, CSSProperties } from 'react';

interface TextBoxProps {
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  className?: string;
  id: string;
  type: 'password' | 'email' | 'text' | 'number';
  required: boolean;
  step?: string
  placeholder?: string;
  label?: string;
  value?: string | number
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextBox = (props: TextBoxProps) => {
  return (
    <>
      {props.label && (
        <label
          className="block uppercase text-black text-sm font-bold mb-2"
          htmlFor={props.id}
        >
          {props.label}
        </label>
      )}
      <input
        required={props.required}
        id={props.id}
        value={props.value}
        type={props.type}
        step={props.step}
        onChange={props.onChange || undefined}
        className={`${props.className? props.className : '' } shadow px-3 py-3 placeholder-gray-500 text-black bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent w-full ease-linear transition-all duration-200`}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
      />
    </>
  );
};

export { TextBox };

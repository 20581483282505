import React from 'react';
import { Link } from 'react-router-dom';
import { CONFIG } from '../../config';
import { UserMenu } from '../User/UserMenu';
const REACT_APP_VERSION = process.env.REACT_APP_VERSION

const routes = [
  { path: '/', name: null },
  // { path: '/admin', name: 'Dashboard' },
  { path: '/admin/products', name: 'Prodotti' },
  { path: '/admin/orders', name: 'Ordini' },
  { path: '/admin/users', name: 'Utenti' },
];

const Navbar = () => {
  return (
    <>
      <nav
        style={{ zIndex: 999 }}
        className="absolute hidden md:flex top-0 left-0 w-full z-10 md:flex-row md:flex-nowrap md:justify-start items-center py-4 px-2 bg-gray-200"
      >
        <div className='text-sm font-thin text-center'>
          <div>
            {(CONFIG.mode === 'production')? 
              <div className='font-bold text-orange-500'>PRODUZIONE</div> : 
              (CONFIG.mode === 'staging')? 
              <div className='font-bold text-green-500'>TEST</div> : 'DEVELOPMENT'}
          </div>
          <div>
            versione {REACT_APP_VERSION}
          </div>
        </div>
        <div className="w-1/2 mx-auto items-center flex justify-between px-2 text-orange-500 uppercase">
          {routes.map(( route , index) => (
            <span key={route.path}>
              {index !== 0 ? <i className={'fas fa-angle-right mx-1'} /> : null}
              <Link className={'hover:text-red-500'} to={route.path}>
                {route.name}
              </Link>
            </span>
          ))}
        </div>

        <ul className="flex flex-row list-none items-center justify-center">
          <UserMenu />
        </ul>
      </nav>
    </>
  );
};

export { Navbar };

import { useCallback, useEffect, useState } from "react"
import { Product, ProductEditInput, User } from "../../storage/types"
import { Button, Loading, TextBox } from "../../ui"
import { Select } from "../../ui/Select"
import { Switch } from '@headlessui/react'
import { useMutation, useQuery } from "@apollo/client"
import { ADMIN_PRODUCT, removeProductData, removeProductVariables, REMOVE_PRODUCT, upadeteProductImageData, upadeteProductImageVariables, updateProductData, updateProductVariables, UPDATE_IMAGE, UPDATE_PRODUCT } from "../../storage/queries/product"
import { ImagePicker } from "../../ui/ImagePicker"
import { transformDeltaFromMidnightToString } from "../../libs/toolkit"
import { UserVisibility } from "../../ui/UserVisibility"
import { ADMIN_USERS } from "../../storage/queries/user"

interface ProductCardProps {
    productId: string;
    products: Product[]
    onRemove: () => void
  }

export const EditProductCard = (props: ProductCardProps) => {
    const { loading, error, data } = useQuery<{ AdminProduct: Product }>(ADMIN_PRODUCT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            productId: props.productId,
        },
    });
    
    const [removeProduct, { error: removeProductError, loading: removeProductLoading }] = useMutation<
        removeProductData,
        removeProductVariables
    >(REMOVE_PRODUCT);


    const [updateProduct, { error: updateProductError, loading: updateProductLoading }] = useMutation<
        updateProductData,
        updateProductVariables
    >(UPDATE_PRODUCT);

    const { loading: loadingUsers, error: errorUsers, data: dataUsers, refetch } = useQuery<{ AdminUsers: User[] }>(ADMIN_USERS, {
        fetchPolicy: 'cache-and-network',
        pollInterval: 5000,
      });
  
    const [product, setProduct]=useState(data?.AdminProduct)
    const [isUpdated, setIsUpdated] = useState(false)
    const [image, setImage] = useState<File>()
    const [productError, setProductError] = useState('')

    useEffect(() => {
    if (data?.AdminProduct) {
        setProduct(data.AdminProduct)
    }
    }, [data]);

    if (loading) return <div>loading</div>
    if (error) return <div>Error on query: {error}</div>
    if (!product) return <div>Something went wrong</div>

    const selectItems = []
    selectItems.push({label: 'Sconosciuto', value: 'unknown'})

    const orderTypeItems = []
    orderTypeItems.push({label: 'Vendita al Kg', value: 'kg'})
    orderTypeItems.push({label: 'Vendita al pezzo', value: 'piece'})
    orderTypeItems.push({label: 'Vendita ', value: 'unknown'})

    const productCheck = (): {success: boolean, message?: string} => {
        if (!product.name || product.name === "") return {success: false, message: 'Nome prodotto errato'}
        if (!product.type) return {success: false, message: 'Tipo prodotto errato'}
        return {success: true}
    }

    const productCheckAndUpdateError = () => {
        const {success, message} = productCheck()
        if (success) setProductError("")
        else setProductError((message)? message : "")
    }

    const update = async () => {
        const {success, message} = productCheck()
        if (success) {
            const tmpProduct : Product = product
            setProductError('')
            const res = await updateProduct({
                variables: {
                  product: {
                        _id: tmpProduct._id,
                        name: tmpProduct.name,
                        ifSponsored: tmpProduct.ifSponsored,
                        ifVisible: tmpProduct.ifVisible,
                        deliverability: (tmpProduct.deliverability)? Number(tmpProduct.deliverability) : 0 ,
                        limitedTo: tmpProduct.limitedTo || [],
                  },
                  image: image
                },
              });
              if (res.errors) {
                  console.log('Something went wrong')
                  setProductError('Prodotto non aggiornato')
              } else {
                setIsUpdated(true)
              }
        } else {
            if (message) setProductError(message)
                else setProductError('Errore generico')
        }
    }

    const remove = async () => {
        await removeProduct({
            variables: {
                productId: props.productId,
            },
          });
          if (removeProductError) {
            console.error('Something went wrong in mutation', removeProductError)
        } else {
            // console.log('Product succesfully inserted', product)
            props.onRemove()
        }
    }

    const imageChanged = async (image: File) => {
        setImage(image)
    }

    if (isUpdated) return  <div className="flex flex-col space-y-2  text-center content-center">
        <div>
            Prodotto modificato correttamente
        </div>
        <div>
            <Button color="blue" onClick={() => {setIsUpdated(false)}} >Continua</Button>
        </div>
    </div>

    const ordinabileFino = () => {
        const acceptedHours = [
            {label: transformDeltaFromMidnightToString(-960), value: "-960"},
            {label: transformDeltaFromMidnightToString(-480), value: "-480"},
            {label: transformDeltaFromMidnightToString(-360), value: "-360"},
            {label: transformDeltaFromMidnightToString(-240), value: "-240"},
            {label: transformDeltaFromMidnightToString(-120), value: "-120"},
            {label: transformDeltaFromMidnightToString(-60), value: "-60"},
            {label: transformDeltaFromMidnightToString(0), value: "0"},
            {label: transformDeltaFromMidnightToString(60), value: "60"},
        ]
        return <Select 
            items={acceptedHours}
            zIndex={999}
            placeholder="Scegliere orario" 
            defaultValue={product.deliverability? product.deliverability.toString() : "0"}
            onChange={(val) => {setProduct({...product,deliverability: val.value})}
        }/>
    }
    return (
      <div className="flex flex-col space-y-2  text-center">
        <label className="font-bold">Modifica Prodotto</label>
        <div className="text-red-500 text-sm">{productError}</div>
        <TextBox 
            id="name" 
            required={true} 
            type="text" 
            value={product.name} 
            onChange={(event) => setProduct({...product, name: event.target.value.toUpperCase()})}
            onBlur={productCheckAndUpdateError}
        />
        {/* <Select 
            zIndex={999} 
            defaultValue={product.type} 
            items={selectItems} placeholder='Tipo di prodotto' 
            onChange={(value) => setProduct({...product, type: value.value})}
            /> */}
        <div className="flex uppercase"><div className="font-semibold pr-2">ID: </div><div>{product.externalId}</div></div>
        <div className="flex uppercase"><div className="font-semibold pr-2">Tipo ordine: </div><div>{product.orderType}</div></div>
        <div className="flex uppercase"><div className="font-semibold pr-2">Grammatura: </div><div>{product.size} gr.</div></div>
        <div className="uppercase">
            <div className="font-semibold pr-2">Ordinabile fino: </div>
            <div>{ordinabileFino()}</div>
        </div>
        <div className="flex uppercase"><div className="font-semibold pr-2">Ingredienti: </div><div>{product.ingredients}</div></div>
        <div className="flex flex-col uppercase"><div className="font-semibold pr-2">Categoria </div><div>{product.externalInfo?.category}</div></div>
        <div className="flex flex-col uppercase">
            <div className="font-semibold pr-2">Visibilita' limitata A: </div>
            <div>{(product.limitedTo?.length)? (product.limitedTo.length === 0) ? "Non limitata" : `${product.limitedTo.length} utenti` : "Non limitata"}</div>
            <UserVisibility 
                allowedUsers={product.limitedTo || []} 
                allUsers={dataUsers?.AdminUsers || []}
                isLoading={loadingUsers}
                allowedUsersChanged={(users) => {setProduct({...product, limitedTo: users})}}
            />
        </div>
        <Switch.Group>
            <div className="flex items-center space-x-2">
                    <Switch.Label className="mr-4">Visibile </Switch.Label>
                    <Switch
                        checked={product.ifVisible}
                        onChange={(enabled: boolean) => {setProduct({...product, ifVisible : enabled})}}
                        className={`${
                            product.ifVisible ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                        >
                        <span
                            className={`${
                                product.ifVisible ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
            </div>
        </Switch.Group>
        <Switch.Group>
            <div className="flex items-center space-x-2">
                    <Switch.Label className="mr-4">Vetrina </Switch.Label>
                    <Switch
                        checked={product.ifSponsored}
                        onChange={(enabled: boolean) => {setProduct({...product, ifSponsored : enabled})}}
                        className={`${
                            product.ifSponsored ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                        >
                        <span
                            className={`${
                                product.ifSponsored ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
            </div>
        </Switch.Group>
        <ImagePicker onImageChanged={imageChanged} image={image} imageURL={product.image?.url}/>

        <div className="flex flex-row space-x-2">
            {(!updateProductLoading && !removeProductLoading)?
                <div className="flex flex-row w-full">
                    <Button disabled={productError!==""} full color="blue" onClick={() => update()}>Modifica</Button>
                    {/* <Button full color="red" onClick={() => remove()}>Elimina</Button> */}
                </div>: <div className="flex-1"><Loading /></div>
            }
        </div>
      </div>
    )
  }
import { gql } from '@apollo/client';
export const ADMIN_ORDERS = gql`
  query ADMIN_ORDERS($userId: ID) {
    AdminOrders(userId: $userId) {
      _id
      user {
        _id
        email
        basicInfo {
          name
        }
      }
      orderType
      involvedDays
      createdAt
      order {
        note
        products {
          product {
            _id
            name
          }
          quantity
        }
      }
      tomorrowOrderBeforeUpdate {
        note
        date
        ifClosed
        products {
          product {
            _id
            name
          }
          quantity
        }
        exceptionsProducts {
          product {
            _id
            name
          }
          quantity
        }
      }
      tomorrowOrderAfterUpdate {
        note
        date
        ifClosed
        products {
          product {
            _id
            name
          }
          quantity
        }
        exceptionsProducts {
          product {
            _id
            name
          }
          quantity
        }
      }
    }
  }
`;

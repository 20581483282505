import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { EditProductCard } from "../../components/Product/EditProduct";
import { transformDeltaFromMidnightToString } from "../../libs/toolkit";
import { ADMIN_PRODUCTS, FORCE_UPDATE_PRODUCTS, updateProductData, updateProductVariables, UPDATE_PRODUCT } from "../../storage/queries/product";
import { Product, ProductTypes } from "../../storage/types";
import { Button, Loading, TextBox } from "../../ui";
import { Select } from "../../ui/Select";
import { Table } from "../../ui/Table";

const convertProductType = (type: ProductTypes) => {
  switch (type) {
    case ProductTypes.unknown:
      return 'sconosciuto';
    default:
      return type
  }
}

const tableColumns : TableColumn<any>[]= [
  {
    name: 'ID',
    selector: (row: Product) => row.externalId,
    sortable: true,
    minWidth: '5%',
    style: { fontWeight: 600 },
  },
  {
    name: 'nome',
    selector: (row: Product) => row.name,
    sortable: true,
    minWidth: '50%',
    style: { fontWeight: 600 },
  },
  // {
  //   name: 'tipo',
  //   selector: (row: Product) => convertProductType(row.type),
  //   sortable: true,
  //   minWidth: '10%',
  //   style: { fontWeight: 600 },
  // },
  {
    name: 'Visibile in APP',
    selector: (row: Product) => (row.ifVisible)? 'Si' : 'No',
    sortable: true,
    minWidth: '5%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Se vetrina',
    selector: (row: Product) => (row.ifSponsored)? 'In vetrina' : 'Non in vetrina',
    sortable: true,
    minWidth: '5%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Foto',
    selector: (row: Product) => (row.image?.url)? 'Si' : 'No',
    sortable: true,
    minWidth: '5%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Ordinabile fino:',
    selector: (row: Product) => (row.deliverability)? transformDeltaFromMidnightToString(row.deliverability) : 'mezzanotte',
    sortable: true,
    minWidth: '5%',
    style: { fontWeight: 600 },
  },
]

interface ProductsViewProps {
    route?: any;
  }
  
  const ProductsView = (props: ProductsViewProps) => {
  
    const { loading, error, data, refetch } = useQuery<{ AdminProducts: Product[] }>(ADMIN_PRODUCTS, {
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
    });

    const [forceUpdateProducts, { error: forceUpdateProductsError, loading: forceUpdateProductsLoading, data: forceUpdateProductsData }] = useMutation(FORCE_UPDATE_PRODUCTS);

    const [products, setProducts] = useState<Product[]>([]) 
    
    useEffect(() => {
      if (data?.AdminProducts) {
        setProducts(data.AdminProducts)
      }
    }, [data]);

    const [selectedProduct, setSelectedProduct] = useState<Product>();

    if (error) return <div>Error: {error}</div>
    if (forceUpdateProductsError) return <div>Error in updating products: {forceUpdateProductsError}</div>
    if (forceUpdateProductsLoading === true) return <div>
      <Loading />
      <div>Caricamento prodotti...</div>
    </div>

    const rowSelected = (row: Product) => {
      if (row._id !== selectedProduct?._id) {
        setSelectedProduct(row)
      } else {
        setSelectedProduct(undefined)
      }
    }

    const onRemoveProduct = () => {
      setSelectedProduct(undefined)
      refetch()
    }

    const onForceUpdateProducts = () => {
      forceUpdateProducts()
    }

    const ForceProductsButton = <Button color="blue" onClick={onForceUpdateProducts}>Aggiorna lista prodotti</Button>

    return (
      <div className="flex space-x-2">
        <div className="container grow-0 w-full p-2">
          <Table 
            columns={tableColumns}
            data={products}
            height={'calc(100vh - 240px)'}
            title='Prodotti'
            loading={loading}
            filterSelector={'name'}
            addElementComponent={ForceProductsButton}
            onRowClick={(row : Product) => {rowSelected(row)}}
            selectedRowId={selectedProduct?._id}
          />
        </div>
        <div className="container w-1/4 p-2 border-2 rounded-md border-orange-300">
          {(selectedProduct)?
              <EditProductCard onRemove={onRemoveProduct} products={products} key={selectedProduct._id} productId={selectedProduct._id}/>
              :null
          }
        </div>
      </div>
    );
  };
  
  export { ProductsView };
  
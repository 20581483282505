import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes} from "react-router-dom";
import {
  ApolloProvider,
} from "@apollo/client";
import { appStorage } from './storage';
import { Admin } from "./layouts/Admin";
import { userLogged } from './storage/userState';
import { Auth } from './layouts/Auth';
import { createApolloClient } from './libs/apollo/createClient';
import { OrdersView } from './views/orders/OrdersView';

const Root = () => {
  const [client, setClient] = useState<any>(undefined);

  const initApp = async () => {
    const auth = await appStorage.getObject('user');
    const apolloClient = await createApolloClient();

    if (auth && auth.user && auth.user._id) {
      // console.log('index -> User already logged')
      userLogged(auth.user._id)
    } else {
      // console.log('index -> User not logged')
    }

    if (apolloClient) {
      setClient(apolloClient);
    } else {
      console.error('Something went wrong in initializing Apollo Client');
    }
  };

  useEffect(() => {
    initApp();
  }, []);

  return (
    <>
      { client ? (
      <ApolloProvider client={client}>
        <BrowserRouter basename=''>
          <Routes>
            <Route path="admin/*" element={<Admin />} />
            {/* Routes autenticazione */}
            <Route path="auth/*" element={<Auth />} />
            <Route path="orders/*" element={<OrdersView />} />
            <Route path="*" element={<Admin />} />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
      ) : <div>loading</div>
      }
    </>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

export interface SelectItem {
  value: any;
  label: string;
}

interface SelectProps {
  label?: string;
  items: SelectItem[];
  placeholder: string;
  defaultValue?: string;
  onChange?: (value: SelectItem) => void;
  zIndex?: number;
}

const Select = (props: SelectProps) => {
  const selecteDefaultValue = props.items.find((item) => item.value === props.defaultValue)
  const [selected, setSelected] = useState<SelectItem | undefined>(selecteDefaultValue);

  useEffect(() => {
    if (selected && props.onChange) {
      props.onChange(selected);
    }
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative w-full" style={{ zIndex: props.zIndex }}>
            <label className="text-sm text-gray-600">{props.label}</label>
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none sm:text-sm">
              <span className="block truncate">
                {selected?.label || props.placeholder || 'Seleziona...'}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg>
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-0 focus:outline-none sm:text-sm"
              >
                {props.items.map((item, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `${active ? ' bg-red-200' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? 'font-medium' : 'font-normal'
                          } block truncate`}
                        >
                          {item.label}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg>                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export { Select };

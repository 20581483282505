import { gql } from '@apollo/client';
import { UserInput, UserEditInput, UserCreateInput } from '../types';

export interface User {
  _id: string;
  email: string;
  basicInfo: {
    name: string;
  };
  auth: {
    createdAt: string;
    createdWith: string;
    isActive: boolean;
    isEmailVerified: boolean;
    permission: {
      scopes: string[];
    };
    lastLogin: string;
  };
  externalInfo: {
    userId: string
    locationId: string
    email: string
  }
}

export const USER_ME = gql`
  query USER_ME {
    UserMe {
      _id
      email
      basicInfo {
        name
      }
      auth {
        createdAt
        createdWith
        isActive
        isEmailVerified
        permission {
          scopes
        }
        lastLogin
      }
    }
  }
`;

export const ADMIN_USERS = gql`
  query ADMIN_USERS {
    AdminUsers {
      _id
      email
      basicInfo {
        name
      }
      auth {
        createdAt
        isActive
        isEmailVerified
        lastLogin
      }
      externalInfo {
        userId
        locationId
        email
      }
    }
  }
`;

export const ADMIN_USER = gql`
  query ADMIN_USER($userId: ID!) {
    AdminUser(_id: $userId) {
      _id
      email
      basicInfo {
        name
      }
      auth {
        createdAt
        isActive
        isEmailVerified
        lastLogin
      }
      externalInfo {
        userId
        locationId
        email
      }
    }
  }
`;

export const ADMIN_USER_ORDER = gql`
  query ADMIN_USER_ORDER($userId: ID!, $date: Date) {
    AdminUserOrder(_id: $userId, date: $date) {
      date
      ifClosed
      products {
        product {
          _id
          name
          type
          ifSponsored
          ifVisible
          orderType
          deliveryTime
          image {
              url
              name
          }
        }
        quantity
      }
      exceptionsProducts {
        product {
          _id
          name
          type
          ifSponsored
          ifVisible
          orderType
          deliveryTime
          image {
              url
              name
          }
        }
        quantity
      }
    }
  }
`;

export const REMOVE_USER = gql`
  mutation ADMIN_USER($userId: ID!) {
    AdminUserRemove(_id: $userId)
  }
`;

export const SEND_WELCOME_EMAIL = gql`
  mutation ADMIN_SEND_WELCOME_EMAIL($userId: ID!) {
    AdminUserSendWelcomeEmail(_id: $userId)
  }
`;

export const EDIT_USER = gql`
  mutation EDIT_USER($userId: ID!, $user: UserEditInput!) {
    AdminUserEdit(_id: $userId, user: $user) {
      _id
      email
      basicInfo {
        name
      }
      auth {
        createdAt
        isActive
        isEmailVerified
        lastLogin
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($user: UserCreateInput!) {
    AdminUserCreate(user: $user) {
      _id
      email
      basicInfo {
        name
      }
      auth {
        createdAt
        isActive
        isEmailVerified
        lastLogin
      }
    }
  }
`;

export interface editUserVariables {
  userId: string;
  user: UserEditInput
}

export interface editUserData {
  AdminUserUpdate: User;
}

export interface createUserVariables {
  user: UserCreateInput
}

export interface createUserData {
  AdminUserCreate: User;
}

export interface removeUserVariables {
  userId: string
}

export interface removeUserData {
  AdminUserRemove: boolean;
}

export interface sendWelcomeEmailVariables {
  userId: string
}

export interface sendWelcomeEmailData {
  AdminUserSendWelcomeEmail: boolean;
}


import { transformOrderType, transformProductOrderTypes } from "../../libs/toolkit"
import { LocalOrder, LocalOrderOrder, Order, OrderType, Product, ProductOrderTypes, ProductTypes, RemoteOrder } from "../../storage/types"

type OrderProps = {
    order?: LocalOrder
}

const combineProducts = (order: RemoteOrder) => {
    const arr1 = order.products || []
    const arr2 = order.exceptionsProducts || []
    const totProducts : {
        product: Product;
        quantity: number;
    }[] = Object.values([...arr1, ...arr2].reduce((acc : any, curr) => {
        if (!curr.product) return acc;
        if(!acc[curr.product._id]) {
          acc[curr.product._id] = curr;
        } else {
          const newQuantity = acc[curr.product._id].quantity + curr.quantity;
          acc[curr.product._id] = {...acc[curr.product._id], quantity: newQuantity}
        }
        return acc;
     }, {}));
     return totProducts
}

export const OrderView = (props: OrderProps) => {

    if (!props.order) return <div>Seleziona un ordine</div> 

    // console.log(props.order)

    const totOrderPre = combineProducts(props.order.tomorrowOrderBeforeUpdate)
    const orderPre = (totOrderPre)?
        (totOrderPre.length < 1) ? <div className="text-orange-500">Nessun prodotto presente</div>:<div>
        {totOrderPre.map((orderProduct) => {
            return <div key={orderProduct.product._id}>{transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} - {orderProduct.product.name}</div>   
            })
        }
    </div> : null


    const order = (props.order.order)?
        (props.order.order.products.length === 0) ? <div className="text-orange-500">Nessun prodotto presente</div>:<div>
        {props.order.order.products.map((orderProduct) => {
            if (orderProduct.product) {
                // check if product is in orderPre
                const productPre = totOrderPre.find((product) => product.product._id === orderProduct.product._id)
                let textColor = 'text-black'
                if (productPre) {
                    console.log(`Prodotto ${orderProduct.product.name} presente anche prima`)
                    if (productPre.quantity === orderProduct.quantity) {
                        console.log('Quantita uguale')
                    } else {
                        console.log('Quantita diversa')
                        if (productPre.quantity > orderProduct.quantity) {
                            console.log('Quantita diminuita')
                            textColor = 'text-orange-500'
                        } else {
                            console.log('Quantita aumentata')
                            textColor = 'text-green-500'
                        }
                    }
                } else {
                    textColor = 'text-green-500'
                    console.log(`Prodotto ${orderProduct.product.name} nuovo`)
                }
                return <div key={orderProduct.product._id} className={textColor}>{transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} - {orderProduct.product.name}</div>   
            } else {
                return null
            }
            })
        }
    </div> : null


    // const totOrderPost = combineProducts(props.order.tomorrowOrderAfterUpdate)
    // const orderPost = (totOrderPost)?
    //     (totOrderPost.length < 1) ? <div className="text-orange-500">Nessun prodotto presente</div>:<div>
    //     {totOrderPost.map((orderProduct) => {
    //         return <div key={orderProduct.product._id}>{transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} - {orderProduct.product.name}</div>   
    //         })
    //     }
    // </div> : null

    let note
    if (props.order.order && props.order.order.note) {
        note = <div>
            <label className="font-bold text-red-600">Note</label>
            <div className="text-red-600">{props.order.order.note}</div>
        </div>
    }

    return (<div className="flex flex-col text-center text-lg border-2 w-full rounded-lg h-full">
        <label className="font-bold">{(props.order.user && props.order.user.basicInfo && props.order.user.basicInfo.name)? props.order.user.basicInfo.name : 'Sconosciuto'}</label>
        Ordine ricevuto in data <label className="font-bold">{new Date(props.order.createdAt).toLocaleString('it-IT')}</label>
        <label>Tipo di ordine: {transformOrderType(props.order)}</label>
        {note}
        <div>
            {order}
        </div>
        {
            (props.order.orderType !== OrderType.specificDays) ? <>
                <div>
                    <label className="font-bold">Ordine precedente</label>
                    <div>
                        {orderPre}
                    </div>
                </div>
                {/* Tolto su richiesta (in quanto uguale a ordine per domani) */}
                {/* <div>
                    <label className="font-bold">Ordine dopo cambio</label>
                    <div>
                        {orderPost}
                    </div>
                </div> */}
            </> : null
        }
        </div>)
}
export enum ProductTypes {
    unknown = "unknown",
}

export enum ProductOrderTypes {
    kg = "kg",
    unknown = "unknown",
}

export class Image {
    name?: string;
    url?: string;
}

export class User {
    "_id": string;
    "email": string;
    "auth": {
        "createdAt": Date
        "isActive": boolean
        "isEmailVerified": boolean
        "lastLogin": Date
    }
    "basicInfo": {
        "name": string
    }
    "externalInfo"?: {
        userId?: string
        locationId?: string
        email?: string
    }
}

export class Product {
    "_id": string;
    "name": string;
    "type": ProductTypes;
    "ifSponsored": boolean;
    "ifVisible": boolean;
    "externalId": string;
    "size": number;
    "ingredients": string;
    "category": string;
    "orderType": ProductOrderTypes;
    "deliveryTime"?: number;
    "deliverability"?: number;
    "limitedTo"?: string[];
    "image"?: Image;
    "externalInfo"?: {
        "name": string
        "category"?: string
        "sku": string
        "size": string
        "typeDescription"?: string
        "description"?: string
        "additionalNote"?: string
        "ingredients"?: string
        "typology"?: string
    }    
}

export class ProductEditInput {
    "_id": string;
    "name": string;
    // "type": ProductTypes;
    "ifSponsored": boolean;
    "ifVisible": boolean;
    "deliverability": number;
    "limitedTo": string[];
    // "externalId": string;
    // "size": number;
    // "category": string;
    // "orderType": ProductOrderTypes
    // "deliveryTime"?: number;
    // "externalInfo"?: {
    //     "name": string
    //     "category": string
    //     "sku": string
    //     "size": string
    //     "typeDescription"?: string
    //     "description"?: string
    //     "additionalNote"?: string
    //     "ingredients"?: string
    //     "typology"?: string
    // }
}

export class UserInput {
    "basicInfo": {
        "name": string
    }
}

export class UserEditInput {
    "basicInfo": {
        "name": string
    }
    "email": string
    "isActive": boolean
}

export class UserCreateInput {
    "email": string
    "basicInfo": {
        "name": string
    }
}

// export class ProductCreateInput {
//     "name": string;
//     "type": ProductTypes;
//     "ifSponsored": boolean;
//     "ifVisible": boolean;
//     "orderType"?: ProductOrderTypes
//     "externalId"?: string;
//     "deliveryTime"?: number;
// }

export class UserLimitedInfo {
    "_id": string;
    "email": string;
    "basicInfo": {
        "name": string
    }
}

export class UserOrder {
    "date": string
    "ifClosed": boolean
    "products": {
        "product": Product
        "quantity": number
    }[]
    "exceptionsProducts": {
        "product": Product
        "quantity": number
    }[]
}

export class Order {
    "_id": string;
    "user"? : UserLimitedInfo
    "createdAt": Date;
    "info": string;
    "products" : {
        "product": {
            "_id": string
            "name": string
            "type": ProductTypes
            "orderType": ProductOrderTypes
        }
        "quantity": number
    }[]
    "exceptionsProducts" : {
        "product": {
            "_id": string
            "name": string
            "type": ProductTypes
            "orderType": ProductOrderTypes
        }
        "quantity": number
    }[]
}

export class UserOrderProduct {
    "product": Product
    "quantity": number
}

export class LocalOrderOrder {
    "products": UserOrderProduct[]
    "note": string
}

export class  RemoteOrder {
  "note": String
  "date": Date
  "ifClosed": Boolean
  "products": [UserOrderProduct]
  "exceptionsProducts": [UserOrderProduct]
}

export enum OrderType {
    justTomorrow = "justTomorrow",
    fromTomorrowOn = "fromTomorrowOn",
    specificDays = "specificDays"
}

export class LocalOrder {
    "_id": string;
    "user"? : UserLimitedInfo
    "orderType": OrderType;
    "involvedDays": number[];
    "createdAt": Date;
    "order" : LocalOrderOrder
    "tomorrowOrderBeforeUpdate": RemoteOrder
    "tomorrowOrderAfterUpdate": RemoteOrder
}

import { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { User } from "../storage/types";
import { ADMIN_USERS } from "../storage/queries/user";

interface UserVisibilityProps {
    isLoading: boolean
    allUsers: User[]
    allowedUsers: string[]
    allowedUsersChanged: (allowedUsers: string[]) => void
}

const UserVisibility = (props: UserVisibilityProps) => {
    if (props.isLoading) return (<div>In caricamento...</div>)

    const allowUser = (userId: string) => {
        const newAllowedUsers = [...props.allowedUsers, userId];
        props.allowedUsersChanged(newAllowedUsers);
    }

    const disallowUser = (userId: string) => {
        const newAllowedUsers = props.allowedUsers.filter((u) => u !== userId);
        props.allowedUsersChanged(newAllowedUsers);
    }

    const changeUserVisibility = (userId: string) => {
        if (props.allowedUsers.includes(userId)) {
            disallowUser(userId);
        } else {
            allowUser(userId);
        }
    }

    const AllowedUsersRows = props.allowedUsers.map((user) => {
        const foundUser = props.allUsers.find((u) => u._id === user);
        if (foundUser) {
            return (
                <div key={foundUser._id} className="flex flex-row items-center">
                    <input type="checkbox" className="mr-2" checked onChange={(event) => changeUserVisibility(foundUser._id)}/>
                    <p className="">{foundUser.basicInfo.name}</p>
                </div>
            )
        }
        return null;
    })

    const notAllowedUsersRows = props.allUsers.filter((user) => !props.allowedUsers.includes(user._id)).map((user) => {
        return (
            <div key={user._id} className="flex flex-row items-center">
                <input type="checkbox" className="mr-2" onChange={(event) => changeUserVisibility(user._id)}/>
                <p className="">{user.basicInfo.name}</p>
            </div>
        )
    })

    return (
        <div className=" h-32 max-h-32 overflow-y-scroll pl-2">
            {AllowedUsersRows}
            {notAllowedUsersRows}
        </div>
    );
};

export { UserVisibility };

import { LocalOrder, Order, OrderType, ProductOrderTypes } from "../storage/types"

export const checkEmail = (email: string) : boolean => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))


export const transformProductOrderTypes = (productType?: ProductOrderTypes, quantity?: number) => {
    switch (productType) {
        case ProductOrderTypes.kg:
            return `${quantity} kg`
        default:
            return quantity
    }
}

export const transformOrderType = (order: LocalOrder) => {
    const orderType = order?.orderType
    switch (orderType) {
        case OrderType.fromTomorrowOn:
            return "Da domani in avanti"
        case OrderType.justTomorrow:
            return "Solamente domani"
        case OrderType.specificDays:
            const giorni : string []= []
            order.involvedDays.forEach((day) => {
                switch (day) {
                    case 0:
                        giorni.push("Dom")
                        break
                    case 1:
                        giorni.push("Lun")
                        break
                    case 2:
                        giorni.push("Mar")
                        break
                    case 3:
                        giorni.push("Mer")
                        break
                    case 4:
                        giorni.push("Gio")
                        break
                    case 5:
                        giorni.push("Ven")
                        break
                    case 6:
                        giorni.push("Sab")
                        break
                }
            })
            return `Giorni specifici (${giorni.join(", ")})` 
        default:
            return "Sconosciuto"
    }
}

export const transformDateInString = (date: Date) => {
    return date.getDate() + '/' + date.getMonth()
}

function time_with_leading_zeros(dt: Date) 
{ 
  const minutes = (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  const hours = dt.getHours()
  return `${hours}:${minutes}`
}

export const transformDeltaFromMidnightToString = (minutes: number) => {
    const deltaDate = new Date()
    deltaDate.setHours(0,0,0,0)
    deltaDate.setMinutes(minutes)
    if (minutes > 0 && minutes < 1440) return `${time_with_leading_zeros(deltaDate)} di notte`
    if (minutes === 0) return 'mezzanotte'
    if (minutes < 0 && minutes > -1440) return `${time_with_leading_zeros(deltaDate)} g.prec.`
    return 'fuori range'
}
import { useCallback, useEffect, useState } from "react"
import { Order, Product, User, UserOrder } from "../../storage/types"
import { Button, Loading, TextBox } from "../../ui"
import { Select } from "../../ui/Select"
import { Switch } from '@headlessui/react'
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { ImagePicker } from "../../ui/ImagePicker"
import { ADMIN_USER, removeUserData, removeUserVariables, REMOVE_USER, editUserData, editUserVariables, EDIT_USER, ADMIN_USER_ORDER, SEND_WELCOME_EMAIL, sendWelcomeEmailVariables, sendWelcomeEmailData } from "../../storage/queries/user"
import { transformDateInString, transformProductOrderTypes } from "../../libs/toolkit"

interface UserOrderCardProps {
    userId: string
}

const printOrder = (order: UserOrder, orderDay: Date) => {
    const products = (order && order.products)? 
        (order.products.length === 0)? 
            <div className="text-orange-500">Nessun prodotto presente</div>:
            <div>
            {order.products.map((orderProduct) => {
                return <div key={orderProduct.product._id}>{transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} - {orderProduct.product.name}</div>   
                })
            }
            </div> : <div className="text-red-500">Qualcosa e' andato storto</div>
    const exceptionsProducts = (order && order.exceptionsProducts)? 
    (order.exceptionsProducts.length === 0)? 
        <div className="text-orange-500">Nessun prodotto presente</div>:
        <div>
        {order.exceptionsProducts.map((orderProduct) => {
            return <div key={orderProduct.product._id}>{transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} - {orderProduct.product.name}</div>   
            })
        }
        </div> : <div className="text-red-500">Qualcosa e' andato storto</div>
    const arr1 = order.products || []
    const arr2 = order.exceptionsProducts || []
    const totProducts : {
        product: Product;
        quantity: number;
    }[] = Object.values([...arr1, ...arr2].reduce((acc : any, curr) => {
        if(!acc[curr.product._id]) {
          acc[curr.product._id] = curr;
        } else {
          acc[curr.product._id].quantity += curr.quantity;
        }
        return acc;
     }, {}));
     const allProducts = (totProducts)? 
     (totProducts.length === 0)? 
         <div className="text-orange-500">Nessun prodotto presente</div>:
         <div>
         {totProducts.map((orderProduct) => {
             return <div key={orderProduct.product._id}>{transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} - {orderProduct.product.name}</div>   
             })
         }
         </div> : <div className="text-red-500">Qualcosa e' andato storto</div>
     return (
        <div>
            <div>
                {allProducts}
            </div>
            <hr />
            <div className="font-mono">Da ordine ricorrente</div>
            <div>
                {products}
            </div>
            <div className="font-mono">Solamente il {transformDateInString(orderDay)}</div>
            <div>
                {exceptionsProducts}
            </div>
        </div>
    )
}

export const UserOrderCard = (props: UserOrderCardProps) => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1);
    const inSevenDays = new Date()
    inSevenDays.setDate(tomorrow.getDate() + 6);

    const [orderDay, setOrderDay] = useState<Date>(tomorrow)

    const [loadOrder, {called, loading: loadingOrder, data: dataOrder, error}] = useLazyQuery<{ AdminUserOrder: UserOrder }>(ADMIN_USER_ORDER, {
        fetchPolicy: 'network-only',
        variables: {
            userId: props.userId,
            date: orderDay.toISOString()
        },
    });

    useEffect(() => {
        loadOrder();
    }, [loadOrder, orderDay]);

    const order = (dataOrder && dataOrder.AdminUserOrder && dataOrder.AdminUserOrder.products && dataOrder.AdminUserOrder.exceptionsProducts)?
        (dataOrder.AdminUserOrder.products.length === 0) ? <div className="text-orange-500">Nessun prodotto presente</div>:<div>
        {dataOrder.AdminUserOrder.products.map((orderProduct) => {
            return <div key={orderProduct.product._id}>{transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} - {orderProduct.product.name}</div>   
            })
        }
    </div> : null

    const openingLabel = (!dataOrder || !dataOrder.AdminUserOrder)? null : 
        (dataOrder.AdminUserOrder.ifClosed === true)? 
            <div className="text-orange-500 text-sm">Il locale sarà chiuso</div> : 
            <div className="text-green-500 text-sm">Il locale sarà aperto</div> ;

    if (error) return <div className="text-red-500 text-sm">
        <div>
            Attenzione: qualcosa non va
        </div>
        <div className="text-xs text-red-300">
            {error.message}
        </div>
    </div>

    const increaseOrderDay = () => {
        const newDate = new Date(orderDay)
        newDate.setDate(orderDay.getDate() + 1);
        setOrderDay(newDate)
    }
    const decreaseOrderDay = () => {
        const newDate = new Date(orderDay)
        newDate.setDate(orderDay.getDate() - 1);
        setOrderDay(newDate)
    }

    return (
        <div>
            <div className="pb-1 font-bold flex justify-between">
                <div>
                    {(orderDay > tomorrow)? 
                        <svg onClick={decreaseOrderDay} className="w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg> :
                        null }
                </div>
                <div>
                    ORDINE PER {transformDateInString(orderDay)}
                </div>
                <div>
                    {(orderDay < inSevenDays)? 
                        <svg onClick={increaseOrderDay} className="w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg> : 
                        null}
                </div>
            </div>
            {openingLabel}
            {loadingOrder? <Loading/> : 
                <div className="flex flex-col w-full  space-x-2">
                    {(dataOrder && dataOrder.AdminUserOrder)? printOrder(dataOrder.AdminUserOrder, orderDay): "Nessun ordine"}
                </div>
            }
        </div>
    )
}


interface UserCardProps {
    userId: string;
    users: User[]
    onRemove: () => void
  }

export const EditUserCard = (props: UserCardProps) => {
    const { loading, error, data } = useQuery<{ AdminUser: User }>(ADMIN_USER, {
        fetchPolicy: 'cache-and-network',
        variables: {
            userId: props.userId,
        },
    });

    const [updateUser, { error: updateUserError, loading: updateUserLoading }] = useMutation<
        editUserData,
        editUserVariables
    >(EDIT_USER);

    const [sendWelcomeEmail, { error: sendWelcomeEmailError, loading: sendWelcomeEmailLoading }] = useMutation<
        sendWelcomeEmailData,
        sendWelcomeEmailVariables
    >(SEND_WELCOME_EMAIL);

    const [user, setUser]=useState(data?.AdminUser)
    const [isUpdated, setIsUpdated] = useState(false)
    const [userError, setUserError] = useState('')
    const [isEmailSent, setIsEmailSent] = useState(false)
    const [emailError, setEmailError] = useState("")

    const userCheck = (): {success: boolean, message?: string} => {
        if (!user?.basicInfo || user.basicInfo.name === "") return {success: false, message: 'Nome utente errato'}
        if (!user.email || user.email === "") return {success: false, message: 'Email errata'}
        if (props.users.findIndex((usr) => {
            if (usr._id === props.userId) return false
            if (!usr.email) return false
            return usr.email.toLocaleLowerCase() === user.email.toLocaleLowerCase()
        }) !== -1) {
            return {success: false, message: 'Email utente già presente'}
        }
        return {success: true}
    }

    const emailCheck = (email: string): {success: boolean, message?: string} => {
        if (props.users.findIndex((usr) => {
            if (!usr.email) return false
            return usr.email.toLocaleLowerCase() === email.toLocaleLowerCase()
        }) !== -1) {
            return {success: false, message: 'Email utente già presente'}
        }
        return {success: true}
    }

    useEffect(() => {
        if (data?.AdminUser) {
            setUser(data.AdminUser)
        }
    }, [data]);

    if (loading) return <div>loading</div>
    if (error) return <div>Error on query: {error}</div>
    if (!user) return <div>Something went wrong</div>

    const update = async () => {
        const {success, message} = userCheck()
        if (success) {
            const tmpUser : User = user
            setUserError('')
            const res = await updateUser({
                variables: {
                  userId: tmpUser._id,
                  user: {
                        email: tmpUser.email,
                        basicInfo: {
                            name: tmpUser.basicInfo.name,
                        },
                        isActive: tmpUser.auth.isActive,
                  },
                },
              });
              if (res.errors) {
                  console.log('Something went wrong')
                  setUserError('Utente non aggiornato')
              } else {
                setIsUpdated(true)
              }
        } else {
            if (message) setUserError(message)
                else setUserError('Errore generico')
        }
    }

    const handleSendWelcomeEmail = async () => {
        setEmailError('')
        const res = await sendWelcomeEmail({
            variables: {
                userId: user._id,
            },
        });
        if (res && res.data && res.data.AdminUserSendWelcomeEmail === true) {
            console.log('Sent')
            setEmailError("")
            setIsEmailSent(true)
        } else {
            console.error('Something went wrong')
            setEmailError('Email non inviata, riprovare o contattare Onubo s.r.l.')
        }
    }

    const userCheckAndUpdateError = () => {
        const {success, message} = userCheck()
        if (success) setUserError("")
        else setUserError((message)? message : "")
    }

    if (isUpdated) return  <div className="flex flex-col space-y-2  text-center content-center">
        <div>
            Utente modificato correttamente
        </div>
        <div>
            <Button color="blue" onClick={() => {setIsUpdated(false)}} >Continua</Button>
        </div>
    </div>

    return (
      <div className="flex flex-col space-y-2  text-center">
        <label className="text-2xl font-bold" >Modifica Utente</label>
        <div className="text-red-500 text-sm">{userError}</div>
        <div className="font-semibold">
            Tipo di utente
        </div>
            {
                (!user.externalInfo?.userId)? <div className="text-orange-500 text-sm">
                    Utente di gestione 
                </div>: <div className="text-green-500 text-sm">
                    Cliente
                </div>
            }
        <div className="flex flex-col">
            <div className="font-semibold">
                Email
            </div>
            <div>
                <div className="text-xs">
                    Da Applicazione                    
                </div>
                <div className="flex space-x-2">
                    { (user.externalInfo?.userId) ?
                    <>
                        <TextBox 
                            id="name" 
                            required={true} 
                            type="text" 
                            value={(user.email)?user.email: ""} 
                            placeholder="Email utilizzata in APP..."
                            onChange={(event) => {
                                setUser({...user, email: event.target.value})}}
                            onBlur={userCheckAndUpdateError}
                        />
                        {
                            (!isEmailSent)?
                                (data && data.AdminUser.email && data.AdminUser.email!=="")? 
                                    (sendWelcomeEmailLoading === true) ? 
                                        <Loading />
                                        : <Button color="yellow" onClick={handleSendWelcomeEmail}>Invia benvenuto</Button>
                                    : null
                                : <div className="text-green-500 text-sm">Email inviata</div>
                        }
                    </>: <div className="text-center w-full">{user.email}</div>
                    }
                </div>
                <div className="text-red-500 text-sm">{emailError}</div>
            </div>
            {(!user.auth.isEmailVerified)?
                <div>
                    <div className="text-xs">
                        Da gestionale                    
                    </div>
                    <div className="flex justify-center">
                        <div>
                            {user.externalInfo?.email}
                        </div>
                        {
                            (user.externalInfo && user.externalInfo.email)?
                                <div className="grid content-center pl-2 cursor-pointer" 
                                    onClick={() => {
                                        if (user.externalInfo?.email) {
                                            setUser({...user, email: user.externalInfo.email});
                                            console.log(user.externalInfo.email)
                                            if (emailCheck(user.externalInfo.email).success === true) {
                                                console.log('eccomi')
                                                setUserError("")
                                            } else {
                                                setUserError("Email utente già presente")
                                            }
                                        }}
                                    }>
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 11l7-7 7 7M5 19l7-7 7 7" /></svg>
                                </div>: null
                            }
                    </div>
                </div> : null
            }
            {(!user.auth.isEmailVerified)? 
                <div className="text-xs text-orange-500">
                    (Email non verificata)
                </div> : null
            }
        </div>
        <div className="flex flex-col">
            <div className="font-semibold">
                Ultimo Accesso
            </div>
            <div>
                {(user.auth.lastLogin) ? new Date(user.auth.lastLogin).toLocaleString("it-IT") : "Mai"}
            </div>
        </div>
        <div className="flex flex-col">
            <div className="font-semibold">
                Denominazione
            </div>
            <div>
                {
                    (user.externalInfo?.userId)? <TextBox 
                        id="name" 
                        required={true} 
                        type="text" 
                        value={user.basicInfo.name} 
                        onChange={(event) => {
                            const basicInfo = { ...user.basicInfo, name: event.target.value }
                            setUser({...user, basicInfo})}}
                        onBlur={userCheckAndUpdateError}
                    />: <div>{user.basicInfo.name}</div>
                }
            </div>
        </div>
        
        {
            (user.auth.isEmailVerified) ? <Switch.Group>
            <div className="flex items-center space-x-2">
                    {
                        (user.externalInfo?.userId) ?
                            <>
                                <Switch.Label className="mr-4 font-semibold">Stato </Switch.Label> 
                                <Switch
                                    checked={user.auth.isActive}
                                    onChange={(enabled: boolean) => {
                                        const auth = {...user.auth, isActive: enabled}
                                        setUser({...user, auth})
                                    }}
                                    className={`${
                                        user.auth.isActive ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex items-center h-6 rounded-full w-11`}
                                    >
                                    <span
                                        className={`${
                                            user.auth.isActive ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block w-4 h-4 transform bg-white rounded-full`}
                                    />
                                </Switch>
                            </>
                            : null
                    }
            </div>
        </Switch.Group> : <div className="flex flex-row space-x-2">
            <div className="grid content-center font-semibold">
                Stato
            </div>
            <div className="grid content-center w-full">
                <div>
                    {user.auth.isActive ? "Attivo" : "Non Attivo"}
                </div>
                <div className="text-xs text-orange-500">
                    (Verificare email)
                </div>
            </div>
        </div>
        }

        <div className="flex flex-row">
            {(user.externalInfo?.userId)? (!updateUserLoading)?
                <div className="flex flex-row w-full  space-x-2">
                    <Button disabled={userError!==""} full color="blue" onClick={() => update()}>Modifica</Button>
                    {/* {(user.auth.isActive === false || !user.auth.isEmailVerified)? <Button full color="red" onClick={() => remove()}>Elimina</Button>: null} */}
                </div>: <div className="flex-1"><Loading /></div> :<div className="flex-1"></div>
            }
        </div>
        <div className="text-xs text-red-500">
            {(updateUserError)? 'Errore durante la modifica': null}
        </div>
        <div className="pt-4">
            {(!user.externalInfo?.userId) ? 
                <div className="text-sm text-orange-500"></div> :
                <UserOrderCard userId={props.userId} />
            }
        </div>
      </div>
    )
  }
import { gql } from '@apollo/client';
import { Product, ProductEditInput } from '../types';

export const ADMIN_PRODUCTS = gql`
  query ADMIN_PRODUCTS {
    AdminProducts {
      _id
      name
      type
      ifSponsored
      ifVisible
      externalId
      size
      ingredients
      category
      orderType
      deliveryTime
      deliverability
      limitedTo
      image {
        url
        name
      }
      externalInfo {
        name
        category
        sku
        size
        typeDescription
        description
        additionalNote
        ingredients
        typology
      }
    }
  }
`;

export const ADMIN_PRODUCT = gql`
  query ADMIN_PRODUCT($productId: ID!) {
    AdminProduct(_id: $productId) {
      _id
      name
      type
      ifSponsored
      ifVisible
      externalId
      size
      ingredients
      category
      orderType
      deliveryTime
      deliverability
      limitedTo
      image {
          url
          name
      }
      externalInfo {
        name
        category
        sku
        size
        typeDescription
        description
        additionalNote
        ingredients
        typology
      }
    }
  }
`;

export const REMOVE_PRODUCT = gql`
  mutation ADMIN_PRODUCT($productId: ID!) {
    AdminProductRemove(_id: $productId)
  }
`;

export const FORCE_UPDATE_PRODUCTS = gql`
  mutation FORCE_UPDATE_PRODUCTS {
    AdminForceProductSync {
      _id
      name
      type
      ifSponsored
      ifVisible
      externalId
      size
      ingredients
      category
      orderType
      deliveryTime
      deliverability
      limitedTo
      image {
          url
          name
      }
      externalInfo {
        name
        category
        sku
        size
        typeDescription
        description
        additionalNote
        ingredients
        typology
      }    
    }
  }
`;


export const UPDATE_PRODUCT = gql`
  mutation UPDATE_PRODUCT($product: ProductEditInput!, $image: Upload) {
    AdminProductUpdate(product: $product, image: $image) {
      _id
      name
      type
      ifSponsored
      ifVisible
      externalId
      size
      ingredients
      category
      orderType
      deliveryTime
      deliverability
      limitedTo
      image {
          url
          name
      }
    }
  }
`;

export const UPDATE_IMAGE = gql`
  mutation UPDATE_IMAGE($_id: string, $image: File!) {
    AdminProductImageUpdate(_id: $_id, image: $image)
  }
`;

export interface updateProductVariables {
  product: ProductEditInput
  image?: any
}

export interface updateProductData {
  AdminProductUpdate: Product;
}

// 
export interface removeProductVariables {
  productId: string
}

export interface removeProductData {
  AdminProductRemove: boolean;
}

export interface upadeteProductImageVariables {
  _id: string;
  image: File
}

export interface upadeteProductImageData {
  AdminProductImageUpdate: boolean;
}

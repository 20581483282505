import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { ADMIN_ORDERS } from "../../storage/queries/order";
import { ADMIN_USERS } from "../../storage/queries/user";
import { LocalOrder, Order, OrderType, User } from "../../storage/types";
import { Select } from "../../ui/Select";
import { Table } from "../../ui/Table";
import { OrderView } from "./OrderView";

const tableColumns : TableColumn<any>[]= [
  {
    name: 'Data',
    // selector: (row: Order) => new Date(row.createdAt).toLocaleString('it-IT'),
    selector: (row: Order) => row.createdAt as any,
    cell: row => new Date(row.createdAt).toLocaleString('it-IT'),
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Utente',
    selector: (row: Order) => (row && row.user && row.user.basicInfo && row.user.basicInfo.name)? row.user.basicInfo.name : 'Sconosciuto',
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Prodotti',
    selector: (row: LocalOrder) => row.order.products.length,
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Tipo di ordine',
    selector: (row: LocalOrder) => {
      if (!row || !row.orderType) {
        return "Sconosciuto"
      }
      switch (row.orderType) {
        case OrderType.fromTomorrowOn:
          return "Da domani in avanti";
        case OrderType.justTomorrow:
          return "Soalmente domani";
        case OrderType.specificDays:
          return "Giorni specifici";
        default:
          return "Sconosciuto"
      }
      },
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  }
]

interface ProductsViewProps {
    route?: any;
  }
  
export const OrdersView = (props: ProductsViewProps) => {
  const [selectedUser, setSelectedUser] = useState<User>()
  
  const { loading, error, data, refetch } = useQuery<{ AdminOrders: LocalOrder[] }>(ADMIN_ORDERS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
    variables: {
      userId: selectedUser?._id,
    },
  });

  const { loading: userLoading, error: userError, data: userData } = useQuery<{ AdminUsers: User[] }>(ADMIN_USERS, {
    fetchPolicy: 'cache-and-network',
  });

  const [orders, setOrders] = useState<LocalOrder[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [selectedOrder, setSelectedOrder] = useState<LocalOrder>()

  useEffect(() => {
    if (data?.AdminOrders) {
      setOrders(data.AdminOrders)
    }
    if (userData?.AdminUsers) {
      setUsers(userData.AdminUsers)
    }
    }, [data, userData]);

  if (error) return <div>Error: {error}</div>

  const selectOrder = (order: LocalOrder) => {
    console.log(selectedOrder)
    console.log(order)
    if (!selectedOrder) {
      setSelectedOrder(order)
    } else {
      if (selectedOrder._id === order._id) {
        setSelectedOrder(undefined)
      } else {
        setSelectedOrder(order)
      }
    }
  }

  const userFilter = () => {
    const usersForFilter = users.map(user => {
      return {
        label: user.basicInfo.name,
        value: user._id,
      }
    })
    // add first option
    usersForFilter.unshift({
      label: 'Tutti gli utenti',
      value: '',
    })
    return <div className="flex">
      <Select 
        items={usersForFilter}
        placeholder="Seleziona Utente"
        zIndex={1000}
        onChange={(value) => {
          // find user
          const user = users.find(user => user._id === value.value)
          setSelectedUser(user)
        }}
      />
    </div>
  }

  return (
    <div className="flex space-x-2">
      <div className={`container grow-0 ${(selectedOrder? 'w-1/2': 'w-full')} p-2`}>
        <div className="flex flex-row justify-between items-center h-16 ">
          <div className="pl-4 text-2xl text-gray-600 font-semibold">
            Ultimi 50 ordini {selectedUser && `di ${selectedUser.basicInfo.name}`}
          </div>
          <div className="w-96">
            {userFilter()}
          </div>
        </div>
        <Table 
          columns={tableColumns}
          data={orders}
          height={'calc(100vh - 240px)'}
          title=''
          loading={loading}
          noDataComponent="Nessun Ordine da Visualizzare"
          defaultSortFieldId='1'
          defaultSortInvert={true}
          onRowClick={selectOrder}
          selectedRowId={selectedOrder?._id}
        />
      </div>
      <div className={`w-1/2`}>
        <OrderView order={selectedOrder} />
      </div>
    </div>
  );

};
  